import { Int64Updater, KeyValue } from './../../models/key-value';
import { Tag } from './../../models/tag';
import { OrganisationService } from './../../services/organisation/organisation.service';
import { TagService } from './../../services/tag/tag.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  page = 1;
  size = 100;
  tname = ' ';

  tags = [];

  isadd = false;
  isedit = false;
  isdelete = false;

  isloadmore = false;
  loading = false;

  organisation: any;
  tagName: string;
  tagDesc: string;

  tag: any;

  noText = 'No';
  cancelText = 'Cancel';

  ismanage = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private tagService: TagService,
    private orgService: OrganisationService
  ) {
      this.organisation = [];

      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      this.translate.get('GENERAL.NO').subscribe((resp: string) => {
        this.noText = resp;
      });
      this.translate.get('GENERAL.CANCEL').subscribe((resp: string) => {
        this.cancelText = resp;
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);

      if ((perm !== undefined && perm !== null && perm.includes('DCM.Administration.Tags'))
      || (perm !== undefined && perm !== null && perm.includes('DCM.Administration.Full'))) {
        this.ismanage = true;
      }
   }

  ngOnInit() {
    this.getTags();
  }

  async getTags() {
    this.loading = true;
    const tgs = await this.tagService.getTags(this.page, this.size, this.tname).toPromise().catch(err => this.handleError(err));
    if (tgs != null && tgs.length > 0) {
      if (tgs.length >= this.size) {
        this.isloadmore = true;
      } else {
        this.isloadmore = false;
      }

      for (const tag of tgs) {
        // tag.created = new Date(tag.created).toLocaleString();
        tag.crt = new Date(tag.created.toString().replace('T', ' ') + ' UTC').toLocaleString();
        if (this.ismanage === false) {
          tag.isUsed = true;
        }
        this.tags.push(tag);
      }
    } else {
      this.isloadmore = false;
      if (this.page === 1) {
        this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
          this.translate.get('GENERAL.NOTAGS').subscribe((resp2: string) => {
            this.showInfo(resp, resp2);
          });
        });
      }
    }
    this.loading = false;
  }

  async loadMore() {
    this.page += 1;
    await this.getTags();
  }

  async addTag() {
    this.loading = true;
    if (this.tagName === undefined || this.tagName == null || this.tagName.trim() === '') {
      this.translate.get('GENERAL.ENTERTAGNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.tagDesc === undefined || this.tagDesc == null || this.tagDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERTAGDESCRIPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const tag = new Tag();
      tag.org_id = this.organisation.organisationId;
      tag.tag_name = this.tagName;
      tag.tag_desc = this.tagDesc;

      const res = await this.tagService.postTag(tag).toPromise().catch(err => this.handleError(err));
      if (res != null && res > 0) {
        // saved
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.TAGCREATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.page = 1;
        this.tags = [];
        await this.getTags();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.TAGNOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async editTag() {
    this.loading = true;
    if (this.tagName === undefined || this.tagName == null || this.tagName.trim() === '') {
      this.translate.get('GENERAL.ENTERTAGNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.tagDesc === undefined || this.tagDesc == null || this.tagDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERTAGDESCRIPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const val = new Int64Updater();
      val.Id = this.tag.tag_id;
      val.Values = [];

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = this.tagName;
      val.Values.push(name);

      const desc = new KeyValue();
      desc.Key = 'desc';
      desc.Value = this.tagDesc;
      val.Values.push(desc);

      const res = await this.tagService.putTag(val).toPromise().catch(err => this.handleError(err));
      if (res != null && res === true) {
        // saved
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.TAGUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.page = 1;
        this.tags = [];
        await this.getTags();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.TAGNOTUPDATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async deleteTag() {
    this.loading = true;
    const res = await this.tagService.deleteTag(this.tag.tag_id).toPromise().catch(err => this.handleError(err));
    if (res != null && res === true) {
      // deleted
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.page = 1;
      this.tags = [];
      await this.getTags();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async getOrganisation() {
    this.loading = true;
    const org = await this.orgService.getOrganisation().toPromise().catch(err => this.handleError(err));
    this.organisation = org;
    this.loading = false;
  }

  showDelete(tag) {
    this.tag = tag;
    this.isedit = false;
    this.isdelete = true;
    this.isadd = false;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + tag.tag_name + '?', 'deletetag', tag);
    });
  }

  async showEdit(tag) {
    this.tag = tag;
    await this.getOrganisation();
    this.tagName = tag.tag_name;
    this.tagDesc = tag.tag_desc;
    this.isedit = true;
    this.isdelete = false;
    this.isadd = false;
  }

  async showAdd() {
    await this.getOrganisation();
    this.isedit = false;
    this.isdelete = false;
    this.isadd = true;
  }

  cancel() {
    this.isedit = false;
    this.isdelete = false;
    this.isadd = false;
    this.tagName = null;
    this.tagDesc = null;
  }

  confirm(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
            if (operation === 'deletetag') {
              await this.deleteTag();
              this.cancel();
            }
            this.isdelete = false;
        },
        reject: () => {
          // Reject logic
          this.isdelete = false;
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    this.cancel();
    if (err != null && err.error != null) {
      if (err.error.MessageText === 'duplicate key value violates unique constraint "t_dcm_rcp_cat_cat_id_uq"') {
        this.translate.get('GENERAL.TAGALREADYEXISTS').subscribe((resp: string) => {
          this.isdelete = false;
          this.confirm(resp, null, null);
        });
      } else {
        console.log(err);
      }
    } else {
      console.log(err);
    }
  }

}
