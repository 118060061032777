import { StringUpdater } from './../../models/key-value';
import { RecipeParameter } from './../../models/recipe-parameter';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/models/key-value';
import { Recipe } from 'src/app/models/recipe';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class RecipeParameterService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRecipeParameters(page, size, rid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/recipeparameter/' + page + '/' + size + '/' + rid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRecipeParameter(param: RecipeParameter) {
    return this.httpclient.post(this.apiUrl + '/api/recipeparameter', param)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putRecipeParameter(rid: number, val: StringUpdater) {
    return this.httpclient.put(this.apiUrl + '/api/recipeparameter/' + rid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipeParameter(param: RecipeParameter): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/recipeparameter/delete/1', param, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipeParameter2(param: RecipeParameter): Observable<any> {
    const val = JSON.stringify(param);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/recipeparameter/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
