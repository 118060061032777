import { Int64Updater } from './../../models/key-value';
import { Tag } from './../../models/tag';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/models/key-value';
import { Step } from 'src/app/models/step';
import { Urls } from 'src/app/models/urls';
import { Variables } from 'src/app/models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getTags(page, size, tname) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/tag/' + page + '/' + size + '/' + orgId + '/' + tname + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postTag(tag: Tag) {
    return this.httpclient.post(this.apiUrl + '/api/tag', tag)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putTag(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/tag/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteTag(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/tag/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
