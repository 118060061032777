import { Unit } from './../../models/unit';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/models/key-value';
import { Tag } from 'src/app/models/tag';
import { Urls } from 'src/app/models/urls';
import { Variables } from 'src/app/models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getUnits(page, size, unit) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/unit/' + page + '/' + size + '/' + orgId + '/' + unit + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postUnit(unit: Unit) {
    return this.httpclient.post(this.apiUrl + '/api/unit', unit)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putUnit(unit: Unit) {
    return this.httpclient.put(this.apiUrl + '/api/unit/'  + 0, unit)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteUnit(unit: Unit): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/unit/delete/0' , unit, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteUnit2(unit: Unit): Observable<any> {
    const val = JSON.stringify(unit);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/unit/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
