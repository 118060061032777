import { Unit } from './../../models/unit';
import { UnitService } from './../../services/unit/unit.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OrganisationService } from 'src/app/services/organisation/organisation.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  units = [];
  organisation: any;

  page = 1;
  size = 100;
  unitfilter = ' ';

  loading = false;
  isloadmore = false;

  noText = 'No';
  cancelText = 'Cancel';

  isadd = false;
  isedit = false;
  isdelete = false;

  valUnitName: string;
  valUnitDesc: string;

  unit: any;

  ismanage = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private orgService: OrganisationService,
    private unitService: UnitService
  ) {
      this.organisation = [];
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      this.translate.get('GENERAL.NO').subscribe((resp: string) => {
        this.noText = resp;
      });
      this.translate.get('GENERAL.CANCEL').subscribe((resp: string) => {
        this.cancelText = resp;
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);

      if ((perm !== undefined && perm !== null && perm.includes('DCM.Administration.Units'))
      || (perm !== undefined && perm !== null && perm.includes('DCM.Administration.Full'))) {
        this.ismanage = true;
      }
   }

  ngOnInit() {
    this.getUnits();
  }

  async getUnits() {
    this.loading = true;
    const unts = await this.unitService.getUnits(this.page, this.size, this.unitfilter).toPromise().catch(err => this.handleError(err));
    if (unts != null && unts.length > 0) {
      if (unts.length >= this.size) {
        this.isloadmore = true;
      } else {
        this.isloadmore = false;
      }

      for (const unit of unts) {
        unit.crt = new Date(unit.created.toString().replace('T', ' ') + ' UTC').toLocaleString();
        if (this.ismanage === false) {
          unit.isUsed = true;
        }
        this.units.push(unit);
      }
    } else {
      this.isloadmore = false;
      if (this.page === 1) {
        this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
          this.translate.get('GENERAL.NOUNITS').subscribe((resp2: string) => {
            this.showInfo(resp, resp2);
          });
        });
      }
    }
    this.loading = false;
  }

  async addUnit() {
    this.loading = true;
    if (this.valUnitName === undefined || this.valUnitName == null || this.valUnitName.trim() === '') {
      this.translate.get('GENERAL.ENTERUNIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.valUnitName !== undefined && this.valUnitName != null && this.valUnitName.trim() !== ''
              && this.valUnitName.length > 20) {
      this.translate.get('GENERAL.UNITNAMETOOLONG').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.valUnitDesc === undefined || this.valUnitDesc == null || this.valUnitDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERUNITDESCRIPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const unit = new Unit();
      unit.org_id = this.organisation.organisationId;
      unit.val_unit_id = this.valUnitName;
      unit.val_unit_desc = this.valUnitDesc;

      const res = await this.unitService.postUnit(unit).toPromise().catch(err => this.handleError(err));
      if (res != null && res.toString().trim() !== '') {
        // saved
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.UNITCREATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.page = 1;
        this.units = [];
        await this.getUnits();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.UNITNOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async editUnit() {
    this.loading = true;
    if (this.valUnitName === undefined || this.valUnitName == null || this.valUnitName.trim() === '') {
      this.translate.get('GENERAL.ENTERUNIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.valUnitDesc === undefined || this.valUnitDesc == null || this.valUnitDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERUNITDESCRIPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const unit = new Unit();
      unit.org_id = this.organisation.organisationId;
      unit.val_unit_id = this.valUnitName;
      unit.val_unit_desc = this.valUnitDesc;

      const res = await this.unitService.putUnit(unit).toPromise().catch(err => this.handleError(err));
      if (res != null && res === true) {
        // saved
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.UNITUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.page = 1;
        this.units = [];
        await this.getUnits();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.UNITNOTUPDATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async deleteUnit() {
    this.loading = true;
    this.unit.crt = null;
    const res = await this.unitService.deleteUnit(this.unit).toPromise().catch(err => this.handleError(err));
    if (res != null && res === true) {
      // deleted
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.UNITDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.page = 1;
      this.units = [];
      await this.getUnits();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.UNITNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async loadMore() {
    this.page += 1;
    await this.getUnits();
  }

  async getOrganisation() {
    this.loading = true;
    const org = await this.orgService.getOrganisation().toPromise().catch(err => this.handleError(err));
    this.organisation = org;
    this.loading = false;
  }

  showDelete(unit) {
    this.unit = unit;
    this.isedit = false;
    this.isdelete = true;
    this.isadd = false;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + unit.val_unit_id + '?', 'deleteunit', unit);
    });
  }

  async showEdit(unit) {
    this.unit = unit;
    await this.getOrganisation();
    this.valUnitName = unit.val_unit_id;
    this.valUnitDesc = unit.val_unit_desc;
    this.isedit = true;
    this.isdelete = false;
    this.isadd = false;
  }

  async showAdd() {
    await this.getOrganisation();
    this.isedit = false;
    this.isdelete = false;
    this.isadd = true;
  }

  cancel() {
    this.isedit = false;
    this.isdelete = false;
    this.isadd = false;
    this.valUnitName = null;
    this.valUnitDesc = null;
  }

  confirm(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
            if (operation === 'deleteunit') {
              // delete unit
              await this.deleteUnit();
              this.cancel();
            }
            this.isdelete = false;
        },
        reject: () => {
          // Reject logic
            this.isdelete = false;
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    this.cancel();
    if (err != null && err.error != null) {
      if (err.error.MessageText === 'duplicate key value violates unique constraint "t_dcm_val_unit_pkey"') {
        this.translate.get('GENERAL.UNITALREADYEXISTS').subscribe((resp: string) => {
          this.confirm(resp, null, null);
        });
      } else {
        console.log(err);
      }
    } else {
      console.log(err);
    }
  }

}
