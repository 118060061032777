import { RecipePermission } from './recipe-permission';

export class Recipe {
    // tslint:disable-next-line: variable-name
    public org_id = 0;
    // tslint:disable-next-line: variable-name
    public rcp_id = 0;
    // tslint:disable-next-line: variable-name
    public rcp_name: string;
    // tslint:disable-next-line: variable-name
    public rcp_desc: string;
    public created: Date;
    public released: Date;
    public status: string;
    public oprator: string;
    // tslint:disable-next-line: variable-name
    public name_sess_ext_id1: string;
    // tslint:disable-next-line: variable-name
    public name_sess_ext_id2: string;
    // tslint:disable-next-line: variable-name
    public name_sess_ext_id3: string;
    // tslint:disable-next-line: variable-name
    public name_sess_ext_id4: string;
    // tslint:disable-next-line: variable-name
    public name_sess_ext_id5: string;
    public Permission: RecipePermission;
}
