export class Step {
    // tslint:disable-next-line: variable-name
    public org_id = 0;
    // tslint:disable-next-line: variable-name
    public rcp_id = 0;
    // tslint:disable-next-line: variable-name
    public step_nr = 0;
    // tslint:disable-next-line: variable-name
    public step_id: string;
    // tslint:disable-next-line: variable-name
    public step_desc: string;
    // tslint:disable-next-line: variable-name
    public step_type: string; // ('N','L')), -- Number; List item
    // tslint:disable-next-line: variable-name
    public unit_id: string;
    // tslint:disable-next-line: variable-name
    public vl_id = 0; // -- To be set in the case of test type L
    public ll = 0;
    public ul = 0;
    // tslint:disable-next-line: variable-name
    public step_mandatory: string; // Y,N
    public Groups: string;
}
