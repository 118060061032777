import { RecipePermission } from './../../models/recipe-permission';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/models/key-value';
import { RecipeParameter } from 'src/app/models/recipe-parameter';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class RecipePermissionService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRecipePermissions(rid, perm) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/recipepermission/' + rid + '/' + perm + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRecipePermission(perm: RecipePermission) {
    return this.httpclient.post(this.apiUrl + '/api/recipepermission', perm)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipePermission(perm: RecipePermission): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/recipepermission/delete/1', perm, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipePermission2(perm: RecipePermission): Observable<any> {
    const val = JSON.stringify(perm);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/recipepermission/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
