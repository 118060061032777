import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = new Urls();
  usmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = this.url.usmApiUrl;
  }

  getUserInfo(uid, empid, email, phone) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/users/ ' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
