import { FileService } from './../../services/file/file.service';
import { StepService } from './../../services/step/step.service';
import { SessionService } from './../../services/session/session.service';
import { SessionFileService } from './../../services/session-file/session-file.service';
import { SessionTextService } from './../../services/session-text/session-text.service';
import { SessionValueService } from './../../services/session-value/session-value.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import * as CryptoJS from 'crypto-js';

import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-session-detail-info',
  templateUrl: './session-detail-info.component.html',
  styleUrls: ['./session-detail-info.component.scss']
})
export class SessionDetailInfoComponent implements OnInit {

  recipes = [];
  recipe: any;

  sess: any;
  session: any;

  loading = false;
  isvisible = false;

  steps = [];
  sessionstepvalues = [];
  sessionstepfiles = [];
  sessionsteptext = [];
  sessionfiles = [];
  sessiontext = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    private sessValservice: SessionValueService,
    private sessTextService: SessionTextService,
    private sessFileService: SessionFileService,
    private sessionService: SessionService,
    private stepService: StepService,
    private fileService: FileService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
  }

  async ngOnInit() {
    this.sess = JSON.parse(localStorage.getItem('session'));
    await this.getSession();
    await this.getRecipe();
    await this.getSteps();
    await this.getSessionText();
    await this.getSessionFiles();
    await this.getSessionValues();
    this.isvisible = true;
  }

  async getSession() {
    this.loading = true;
    const sess = await this.sessionService.getSessions(1, 10, 0, this.sess.sess_id)
    .toPromise().catch(err => this.handleError(err));

    if (sess !== null && sess.length > 0) {
      for (const ss of sess) {
        this.session = ss;
      }

      this.loading = false;
    } else {
      this.loading = false;
      this.translate.get('GENERAL.COULDNOTGETSESSIONDATA').subscribe((resp: string) => {
        this.translate.get('GENERAL.INFO').subscribe((info: string) => {
          this.showInfo(info, resp);
        });
      });
    }
  }

  async getRecipe() {
    this.loading = true;
    const rcps = await this.recipeService.getRecipes(1, 10, this.session.rcp_id, ' ', 'notfiltering')
    .toPromise().catch(err => this.handleError(err));

    if (rcps != null && rcps.length > 0) {
      for (const rcp of rcps) {
        this.recipe = rcp;
      }
    }
    this.loading = false;
  }

  async getSteps() {
    this.loading = true;
    let page = 0;
    let run = true;
    while (run === true) {
      page += 1;
      const steps = await this.stepService.getSteps(page, 500, this.recipe.rcp_id, 0).toPromise()
      .catch(err => this.handleError(err));
      if (steps != null && steps.length > 0) {
        for (const step of steps) {
          step.result = null;
          step.stext = [];
          step.sfiles = [];
          step.value = null;

          if (step.step_mandatory === 'Y') {
            this.translate.get('GENERAL.YES').subscribe((resp: string) => {
              step.man = true;
              step.mand = resp;
            });
          } else if (step.step_mandatory === 'N') {
            this.translate.get('GENERAL.NO').subscribe((resp: string) => {
              step.man = false;
              step.mand = resp;
            });
          }
          const stext = await this.sessTextService.getSessionText(this.session.sess_id, step.step_nr).toPromise()
          .catch(err => this.handleError(err));

          if (stext != null && stext.length > 0) {
            step.stext = stext;
          }

          const sfiles = await this.sessFileService.getSessionFiles(this.session.sess_id, ' ', step.step_nr).toPromise()
          .catch(err => this.handleError(err));

          if (sfiles != null && sfiles.length > 0) {
            step.sfiles = sfiles;
          }

          const svalues = await this.sessValservice.getSessionValues(this.session.sess_id, step.step_nr).toPromise()
          .catch(err => this.handleError(err));

          if (svalues != null && svalues.length > 0) {
            for (const svalue of svalues) {
              step.value = svalue.val;
              if (svalue.res === 'P') {
                this.translate.get('GENERAL.PASS').subscribe((resp: string) => {
                  step.result = resp;
                  step.color = 'green';
                });
              } else if (svalue.res === 'F') {
                this.translate.get('GENERAL.FAIL').subscribe((resp: string) => {
                  step.result = resp;
                  step.color = 'red';
                });
              } else {
                this.translate.get('GENERAL.PENDING').subscribe((resp: string) => {
                  step.result = resp;
                  step.color = 'yellow';
                });
              }
            }
          }

          this.steps.push(step);
        }
      } else {
        run = false;
      }
    }
    this.loading = false;
  }

  async getSessionText() {
    this.loading = true;
    const stext = await this.sessTextService.getSessionText(this.session.sess_id, 0).toPromise()
    .catch(err => this.handleError(err));

    if (stext != null && stext.length > 0) {
      for (const st of stext) {
        if (st.step_nr === 0) {
          this.sessiontext.push(st);
        } else {
          this.sessionsteptext.push(st);
        }
      }
    }
    this.loading = false;
  }

  async getSessionFiles() {
    this.loading = true;
    const sfiles = await this.sessFileService.getSessionFiles(this.session.sess_id, ' ', 0).toPromise()
    .catch(err => this.handleError(err));

    if (sfiles != null && sfiles.length > 0) {
      for (const sfile of sfiles) {
        if (sfile.step_nr === 0) {
          this.sessionfiles.push(sfile);
        } else {
          this.sessionstepfiles.push(sfile);
        }
      }
    }
    this.loading = false;
  }

  async getSessionValues() {
    this.loading = true;
    const svalues = await this.sessValservice.getSessionValues(this.session.sess_id, 0).toPromise()
    .catch(err => this.handleError(err));

    if (svalues != null && svalues.length > 0) {
      for (const svalue of svalues) {
        this.sessionstepvalues.push(svalue);
      }
    }
    this.loading = false;
  }

  async download(fileid) {
    this.loading = true;
    this.translate.get('GENERAL.INFO').subscribe((err: string) => {
      this.translate.get('GENERAL.DOWNLOADINGFILE').subscribe((resp: string) => {
        this.showInfo(err, resp);
      });
    });
    const file = await this.fileService.getFiles(1, 1, fileid, ' ', ' ', ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (file != null && file.length > 0) {
      const fileinfo = await this.fileService.getFile(file[0].bucketName, file[0].fileId).toPromise()
      .catch(err => this.handleError(err));

      if (fileinfo != null) {
        const dfile = await this.fileService.downloadFile(fileinfo.url, fileinfo.metaData['Content-Type'])
        .toPromise().catch(err => this.handleError(err));
        if (dfile != null) {
          await FileSaver.saveAs(dfile, file[0].externalFileName);
          this.translate.get('GENERAL.SUCCESS').subscribe((err: string) => {
            this.translate.get('GENERAL.FILEDOWNLOADED').subscribe((resp: string) => {
              this.showSuccess(err, resp);
            });
          });
        } else {
          // failed
          this.translate.get('GENERAL.ERROR').subscribe((err: string) => {
            this.translate.get('GENERAL.FAILEDTODOWNLOAD').subscribe((resp: string) => {
              this.showError(err, resp);
            });
          });
        }
      } else {
        // failed
        this.translate.get('GENERAL.ERROR').subscribe((err: string) => {
          this.translate.get('GENERAL.FAILEDTODOWNLOAD').subscribe((resp: string) => {
            this.showError(err, resp);
          });
        });
      }
    } else {
      // failed
        this.translate.get('GENERAL.ERROR').subscribe((err: string) => {
          this.translate.get('GENERAL.FAILEDTODOWNLOAD').subscribe((resp: string) => {
            this.showError(err, resp);
          });
        });
    }
    this.loading = false;
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }

}
