import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrls: ['./session-detail.component.scss']
})
export class SessionDetailComponent implements OnInit {

  fromText = '';
  tillText = '';

  start = ' ';
  end = ' ';

  fromrange: any;
  tillrange: any;

  loading = false;
  recipes = [];
  recipe: any;

  sessions = [];
  isvisible = false;

  name = '';

  isapply = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private analyticsService: AnalyticsService,
    private recipeService: RecipeService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      this.translate.get('GENERAL.FROM').subscribe((resp: string) => {
        this.fromText = resp;
      });
      this.translate.get('GENERAL.TILL').subscribe((resp: string) => {
        this.tillText = resp;
      });
  }

  async ngOnInit() {
    const v = new Variables();
    const p = localStorage.getItem('ups');
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);

    if ((perm !== undefined && perm !== null && perm.includes('DCM.Analytics.SessionDetail'))
    || (perm !== undefined && perm !== null && perm.includes('DCM.Administration.Full'))) {
      this.isapply = true;
    }

    const frange = localStorage.getItem('filter-fromrange');
    const trange = localStorage.getItem('filter-tillrange');
    const frecipe = localStorage.getItem('filter-recipe');
    const detail = localStorage.getItem('detail');

    if (frange != null && trange != null && frecipe != null
        && frange !== undefined && trange !== undefined && frecipe !== undefined) {
      this.fromrange = new Date(frange);
      this.tillrange = new Date(trange);
      this.recipe = JSON.parse(frecipe);
    }

    await this.getRecipes();

    if (this.recipe != null && this.recipe !== undefined) {
      if (detail !== undefined && detail != null && detail === 'detail') {
        await this.apply();
      }
    }
    localStorage.setItem('detail', 'notdetail');
  }

  async getSessions() {
    this.sessions = [];
    let pass = '';
    let fail = '';
    let pending = '';
    this.translate.get('GENERAL.PASS').subscribe((resp: string) => {
      pass = resp;
    });
    this.translate.get('GENERAL.FAIL').subscribe((resp: string) => {
      fail = resp;
    });
    this.translate.get('GENERAL.PENDING').subscribe((resp: string) => {
      pending = resp;
    });
    this.loading = true;
    this.name = this.recipe.name_sess_ext_id1;
    const sess = await this.analyticsService.getAnalyticsSessions(this.recipe.rcp_id,
       this.start, this.end)
    .toPromise().catch(err => this.handleError(err));
    if (sess !== null && sess.length > 0) {
      for (const ss of sess) {
        const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
        const d = new Date(new Date(ss.sess_start.toString().replace('T', ' ') + ' UTC')).toLocaleString('en-US', options);
        const t = new Date(new Date(ss.sess_start.toString().replace('T', ' ') + ' UTC')).toLocaleString('en-US', { hour12: false });
        const spl = t.split(',');
        const time = d + ',' + spl[1];
        ss.time = time;
        if (ss.res === 'P') {
          ss.result = pass;
          ss.color = 'green';
        } else if (ss.res === 'F') {
          ss.result = fail;
          ss.color = 'red';
        } else {
          ss.result = pending;
          ss.color = 'yellow';
        }

        this.sessions.push(ss);
      }

      this.isvisible = true;
      this.loading = false;
    } else {
      this.loading = false;
      this.isvisible = false;
      this.translate.get('GENERAL.NODATA').subscribe((resp: string) => {
        this.translate.get('GENERAL.INFO').subscribe((info: string) => {
          this.showInfo(info, resp);
        });
      });
    }
  }

  async getRecipes() {
    this.loading = true;
    this.recipes = [];
    const reps = await this.recipeService.getRecipes(0, 0, 0, ' ', ' ').toPromise().catch(err => this.handleError(err));
    if (reps != null && reps.length > 0) {
      for (const rep of reps) {
        this.recipes.push({ label: rep.rcp_name, value: rep});
        if (this.recipe != null && this.recipe !== undefined && this.recipe.rcp_id === rep.rcp_id) {
          this.recipe = rep;
        }
      }
    } else {
    }
    this.loading = false;
  }

  apply() {
    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else if (this.recipe === undefined || this.recipe === null) {
      this.translate.get('GENERAL.SELECTRECIPE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      this.start = this.fromrange.toJSON().toString();
      this.end = this.tillrange.toJSON().toString();

      localStorage.setItem('filter-fromrange', this.fromrange.toString());
      localStorage.setItem('filter-tillrange', this.tillrange.toString());
      localStorage.setItem('filter-recipe', JSON.stringify(this.recipe));

      // call function here
      this.getSessions();
    }
  }

  showDetail(sess) {
    localStorage.setItem('detail', 'detail');

    localStorage.setItem('session', JSON.stringify(sess));
    this.router.navigateByUrl('session-detail-info');
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }

  test() {
    const now = new Date();
    this.fromrange = new Date(now.setFullYear(new Date().getFullYear() - 1));
    this.tillrange = new Date(now.setFullYear(new Date().getFullYear() + 1));
  }

}
