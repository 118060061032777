export class RecipeParameter {
    // tslint:disable-next-line: variable-name
    public rcp_id: number;
    // tslint:disable-next-line: variable-name
    public par_id: string;
    // tslint:disable-next-line: variable-name
    public par_desc: string;
    // tslint:disable-next-line: variable-name
    public par_val: number;
    public Groups: string;
}
