import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getAnalyticsSessions(rid, start, end) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/analytics/analyticsession/' + rid + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getAnalyticsSessionValues(snr, rid, start, end) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/analytics/analyticsessionvalue/' + snr + '/' + rid + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
