import { Step } from './../../models/step';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/models/key-value';
import { RecipeParameter } from 'src/app/models/recipe-parameter';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getSteps(page, size, rid, snr) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/step/' + page + '/' + size + '/' + rid + '/' + snr + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postStep(step: Step) {
    return this.httpclient.post(this.apiUrl + '/api/step', step)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putStep(rid: number, val: StringUpdater) {
    return this.httpclient.put(this.apiUrl + '/api/step/' + rid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteStep(step: Step): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/step/delete/1', step, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteStep2(step: Step): Observable<any> {
    step.step_id = '';
    step.step_desc = '';
    step.step_mandatory = '';
    step.step_type = '';
    step.ul = 0;
    step.ll = 0;
    step.unit_id = '';
    step.vl_id = 0;

    const val = JSON.stringify(step);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/step/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
