import { PermissionService } from './services/permission/permission.service';
import { UserService } from './services/user/user.service';
import { GroupService } from './services/group/group.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { Variables } from './models/variables';
import { KeycloakService } from 'keycloak-angular';
import { FileService } from './services/file/file.service';
import { LanguageService } from './services/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  items: MenuItem[] = [];
  load = false;
  loading = false;

  recipeText = 'Recipe';
  analyticsText = 'Analytics';
  sessionOverviewText = 'Session overview';
  sessionDetailText = 'Session detail';
  valueTrendText = 'Test step value trend';
  adminText = 'Administration';
  tagsText = 'Tags';
  unitsText = 'Units';

  uid = 0;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private groupService: GroupService,
    private userService: UserService,
    private permService: PermissionService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService
  ) {
    if ('serviceWorker' in navigator) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      });
    }

      // localStorage.clear();
      this.router.navigateByUrl('');
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      // localStorage.setItem('organisation-id', decorg);
      // localStorage.setItem('user-id', decusr);

      this.translate.get('GENERAL.RECIPE').subscribe((resp: string) => {
        this.recipeText = resp;
      });
      this.translate.get('GENERAL.SESSIONOVERVIEW').subscribe((resp: string) => {
        this.sessionOverviewText = resp;
      });
      this.translate.get('GENERAL.SESSIONDETAILS').subscribe((resp: string) => {
        this.sessionDetailText = resp;
      });
      this.translate.get('GENERAL.TESTSTEPVALUETREND').subscribe((resp: string) => {
        this.valueTrendText = resp;
      });
      this.translate.get('GENERAL.ANALYTICS').subscribe((resp: string) => {
        this.analyticsText = resp;
      });
      this.translate.get('GENERAL.UNITS').subscribe((resp: string) => {
        this.unitsText = resp;
      });
      this.translate.get('GENERAL.TAGS').subscribe((resp: string) => {
        this.tagsText = resp;
      });
      this.translate.get('GENERAL.ADMINISTRATION').subscribe((resp: string) => {
        this.adminText = resp;
      });

      this.loading = true;
   }

  async ngOnInit() {
    // if (this.load === false) {
    //   return;
    // }
    this.load = true;

    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
  }

  async setIcon() {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'dcm-icon', 'dcm-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('dcm-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.userService.getUserInfo(0, 0, eml, null).toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      this.uid = ress.userId;
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.showInfo('null');
      await this.getGroups();
    } else {
      this.loading = false;
      this.load = false;
      // this.router.navigateByUrl('/unauthorizeduser');
      await this.showInfo('user');
    }
  }

  async getGroups() {
    this.loading = true;
    const uid = this.uid;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }
    // localStorage.setItem('my-groups', mygroups);
    const v = new Variables();
    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    const name = CryptoJS.AES.encrypt(username, v.pass);
    localStorage.setItem('un', name);
    // localStorage.setItem('user-name', username);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(groupids, user.organisationName, mygroups);

    this.loading = false;
    // this.router.navigateByUrl('recipes');
  }

  async getPermissions(groupids, org, mygroups) {
    this.loading = true;
    const permissions = [];
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('DCM.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
  }

  async buildMenu(perm) {
    // perm = ['DCM.Administration.Full', 'DCM.Section.Recipe', 'DCM.Section.Analytics'];
    if (perm != null && perm.length > 0) {
      let isrecipe = false;
      if (perm.includes('DCM.Section.Recipe')
       || perm.includes('DCM.Administration.Full')) {
        isrecipe = true;
        this.items.push({
          label: this.recipeText,
          id: 'recipe',
          routerLink: ['/recipes']
        });
      }
      let isanalytics = false;
      if (perm.includes('DCM.Section.Analytics') || perm.includes('DCM.Administration.Full')) {
        isanalytics = true;
      }
      const analyticsitems = [];
      if (isanalytics === true) {
        if (perm.includes('DCM.Analytics.SessionOverview') || perm.includes('DCM.Administration.Full')) {
          isanalytics = true;
          analyticsitems.push({ label: this.sessionOverviewText, id: 'sessionoverview', routerLink: ['/session-overview'] });
        }
        if (perm.includes('DCM.Analytics.SessionDetail') || perm.includes('DCM.Administration.Full')) {
          isanalytics = true;
          analyticsitems.push({ label: this.sessionDetailText, id: 'sessiondetail', routerLink: ['/session-detail'] });
        }
        if (perm.includes('DCM.Analytics.TestStepValueTrend') || perm.includes('DCM.Administration.Full')) {
          isanalytics = true;
          analyticsitems.push({ label: this.valueTrendText, id: 'teststepvaluetrend', routerLink: ['/test-step-value-trend'] });
        }
      }

      if (isanalytics === true) {
        this.items.push({
          label: this.analyticsText,
          id: 'analytics',
          items: analyticsitems
        });
      }

      let isadmin = false;
      const adminitems = [];
      if (perm.includes('DCM.Section.Administration')
      || perm.includes('DCM.Administration.Full')) {
        isadmin = true;
        adminitems.push({ label: this.tagsText, id: 'tags', routerLink: ['/tags'] });
      }
      if (perm.includes('DCM.Section.Administration')
      || perm.includes('DCM.Administration.Full')) {
        isadmin = true;
        adminitems.push({ label: this.unitsText, id: 'units', routerLink: ['/units'] });
      }

      if (isadmin === true) {
        this.items.push({
          label: this.adminText,
          id: 'administration',
          items: adminitems
        });
      }
      if (isrecipe === true) {
        this.router.navigateByUrl('recipes');
      }

      if (this.items.length === 0) {
        this.router.navigateByUrl('/unauthorized-user');
        this.load = false;
      }
    } else {
      this.router.navigateByUrl('/unauthorized-user');
      this.load = false;
    }
  }

  async showInfo(info) {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        // this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        // ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        // this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('message', 'USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }
}
