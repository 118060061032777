import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SessionText } from 'src/app/models/session-text';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class SessionTextService {

  url = new Urls();
  dcmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.dcmApiUrl = this.url.apiUrl;
  }

  getSessionText(sid, snr) {
    // tslint:disable-next-line: max-line-length
    const url = this.dcmApiUrl + '/api/sessiontext/' + sid + '/' + snr + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postSessionText(st: SessionText) {
    return this.httpclient.post<any>(this.dcmApiUrl + '/api/sessiontext', st)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putSessionText(id: string, st: SessionText): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.dcmApiUrl + '/api/sessiontext/' + id, st, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteSessionText(st: SessionText): Observable<any> {
    const val = JSON.stringify(st);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.dcmApiUrl + '/api/sessiontext/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
