import { RecipePermission } from './../../models/recipe-permission';
import { RecipeTag } from './../../models/recipe-tag';
import { GroupService } from './../../services/group/group.service';
import { RecipeTagService } from './../../services/recipe-tag/recipe-tag.service';
import { RecipePermissionService } from './../../services/recipe-permission/recipe-permission.service';
import { TagService } from './../../services/tag/tag.service';
import { RecipeParameter } from './../../models/recipe-parameter';
import { StringUpdater, KeyValue, Int64Updater } from './../../models/key-value';
import { Step } from './../../models/step';
import { StepService } from './../../services/step/step.service';
import { RecipeParameterService } from './../../services/recipe-parameter/recipe-parameter.service';
import { RecipeService } from './../../services/recipe/recipe.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OrganisationService } from 'src/app/services/organisation/organisation.service';
import { UnitService } from 'src/app/services/unit/unit.service';
import * as CryptoJS from 'crypto-js';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Recipe } from 'src/app/models/recipe';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit {

  rpage = 1;
  ppage = 1;
  spage = 1;
  size = 100;
  rname = ' ';
  rid = 0;
  filter = 'notfiltering';
  recipefilter: string;

  recipes = [];
  recipe: any;

  parameters = [];
  parameter: any;

  steps = [];
  step: any;

  mands = [];
  units = [];

  rtags = [];
  rpermissions = [];
  groups = [];
  tags = [];

  rloadmore = false;
  ploadmore = false;
  sloadmore = false;

  isnorecipes = false;
  loading = true;

  isaddstep = false;
  iseditstep = false;
  isaddparameter = false;
  iseditparameter = false;
  isaddrecipe = false;
  iseditrecipe = false;
  ispermadd = false;
  istagadd = false;
  issessionadd = false;

  isdelete = false;

  cancelText = 'Cancel';
  noText = 'No';

  stepNr: number;
  stepId: string;
  stepDesc: string;
  stepMand: any;
  stepUnit: any;
  stepLow: number;
  stepHigh: number;

  paramName: string;
  paramDesc: string;
  paramValue: number;

  editmode = false;

  filterform: FormGroup;

  rpermlist = [];
  rtagslist = [];

  sessionid2: string;
  sessionid3: string;
  sessionid4: string;
  sessionid5: string;
  organisation: any;

  statuses = [];
  recipegroups = [];
  recipeName: string;
  recipeDesc: string;
  recipeStatus: any;
  recipeSession: string;
  recipeGroup: any;

  isdetail = false;

  activeIndex = 0;

  isblockedorreleased = false;

  divname = 'notdisableddiv';

  ismanage = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private orgService: OrganisationService,
    private unitService: UnitService,
    private recipeService: RecipeService,
    private paramService: RecipeParameterService,
    private stepService: StepService,
    private tagService: TagService,
    private rtagService: RecipeTagService,
    private permService: RecipePermissionService,
    private groupService: GroupService,
    private fb: FormBuilder
  ) {
    this.organisation = [];
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.translate.get('GENERAL.CANCEL').subscribe((resp: string) => {
      this.cancelText = resp;
    });

    this.translate.get('GENERAL.NO').subscribe((resp: string) => {
      this.noText = resp;
    });

    this.translate.get('GENERAL.YES').subscribe((yes: string) => {
      this.translate.get('GENERAL.NO').subscribe((no: string) => {
        this.mands.push({ label: yes + ' ', value: 'Y', id: 'mandyes'});
        this.mands.push({ label: no + ' ', value: 'N', id: 'mandno'});
      });
    });
   }

  ngOnInit() {
    this.filterform = this.fb.group({
      filter: new FormControl()
    });

    const v = new Variables();
    const p = localStorage.getItem('ups');
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);

    if ((perm !== undefined && perm !== null && perm.includes('DCM.Recipe.Manage'))
    || (perm !== undefined && perm !== null && perm.includes('DCM.Administration.Full'))) {
      this.ismanage = true;
    }

    this.getRecipes();
  }

  async getRecipes() {
    this.loading = true;
    this.isnorecipes = false;
    if (this.recipefilter === undefined || this.recipefilter === null || this.recipefilter.trim() === '') {
      this.filter = 'notfiltering';
      this.rname = ' ';
    } else {
      this.filter = 'filter';
      this.rname = this.recipefilter;
    }
    const rs = await this.recipeService.getRecipes(this.rpage, this.size, this.rid, this.rname, this.filter).toPromise()
    .catch(err => this.handleError(err));
    if (rs != null && rs.length > 0) {
      if (rs.length >= this.size) {
        this.rloadmore = true;
      } else {
        this.rloadmore = false;
      }
      for (const r of rs) {
        r.color = 'transparent';
        if (r.status === 'E') {
          this.translate.get('GENERAL.EDIT').subscribe((res: string) => {
            r.stat = res;
          });
          r.statcolor = 'yellow';
        }
        if (r.status === 'R') {
          this.translate.get('GENERAL.RELEASED').subscribe((res: string) => {
            r.stat = res;
          });
          r.statcolor = 'green';
        }
        if (r.status === 'B') {
          this.translate.get('GENERAL.BLOCKED').subscribe((res: string) => {
            r.stat = res;
          });
          r.statcolor = 'red';
        }

        this.recipes.push(r);
      }
    } else {
      this.rloadmore = false;
      if (this.rpage === 1) {
        this.isnorecipes = true;
      }
    }
    if (this.rpage === 1) {
      if (this.recipes != null && this.recipes.length > 0) {
        await this.recipeClicked(this.recipes[0]);
      }
    }
    this.loading = false;
  }

  async filterRecipes() {
    this.recipefilter = this.filterform.value.filter;
    this.rpage = 1;
    this.recipes = [];
    await this.getRecipes();
  }

  async getSteps() {
    if (this.recipe !== undefined && this.recipe != null) {
      this.loading = true;
      const stps = await this.stepService.getSteps(this.spage, this.size, this.recipe.rcp_id, 0).toPromise()
      .catch(err => this.handleError(err));
      if (stps != null && stps.length > 0) {
        if (stps.length >= this.size) {
          this.sloadmore = true;
        } else {
          this.sloadmore = false;
        }
        for (const stp of stps) {
          if (stp.step_mandatory === 'Y') {
            this.translate.get('GENERAL.YES').subscribe((resp: string) => {
              stp.mand = resp;
            });
          }
          if (stp.step_mandatory === 'N') {
            this.translate.get('GENERAL.NO').subscribe((resp: string) => {
              stp.mand = resp;
            });
          }
          this.steps.push(stp);
        }
      } else {
        this.sloadmore = false;
      }
      this.loading = false;
    }
  }

  async getParameters() {
    if (this.recipe !== undefined && this.recipe != null) {
      this.loading = true;
      const params = await this.paramService.getRecipeParameters(this.ppage, this.size, this.recipe.rcp_id).toPromise()
      .catch(err => this.handleError(err));
      if (params != null && params.length > 0) {
        if (params.length >= this.size) {
          this.ploadmore = true;
        } else {
          this.ploadmore = false;
        }
        for (const param of params) {
          this.parameters.push(param);
        }
      } else {
        this.ploadmore = false;
      }
      this.loading = false;
    }
  }

  async getUnits(step) {
    this.loading = true;
    let page = 0;
    let run = true;
    while (run === true) {
      page += 1;
      const uns = await this.unitService.getUnits(page, 500, ' ').toPromise()
      .catch(err => this.handleError(err));
      if (uns != null && uns.length > 0) {
        for (const un of uns) {
          this.units.push({ label: un.val_unit_id + ' ', value: un, id: un.val_unit_id });
          if (step != null && step !== undefined && un.val_unit_id === step.unit_id) {
            this.stepUnit = un;
          }
        }
      } else {
        run = false;
      }
    }
    this.loading = false;
  }

  async getRTags() {
    this.loading = true;
    this.rtagslist = [];
    const rtags = await this.rtagService.getRecipeTags(this.recipe.rcp_id).toPromise()
    .catch(err => this.handleError(err));
    if (rtags !== null && rtags.length > 0) {
      for (const rtag of rtags) {
        this.rtags.push(rtag);
        this.rtagslist.push(rtag.tag_name);
      }
    }
    this.loading = false;
  }

  async getRPermissions() {
    this.loading = true;
    this.rpermlist = [];
    const rperms = await this.permService.getRecipePermissions(this.recipe.rcp_id, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (rperms !== null && rperms.length > 0) {
      for (const rperm of rperms) {
        if (rperm.permission === 'W') {
          this.translate.get('GENERAL.WRITE').subscribe((resp: string) => {
            rperm.perm = rperm.userGroup + ' (' + resp + ')';
          });
        } else if (rperm.permission === 'R') {
          this.translate.get('GENERAL.READ').subscribe((resp: string) => {
            rperm.perm = rperm.userGroup + ' (' + resp + ')';
          });
        }
        this.rpermissions.push(rperm);
        this.rpermlist.push(rperm.userGroup);
      }
    }
    this.loading = false;
  }

  async getTags() {
    this.loading = true;
    let page = 0;
    let run = true;
    while (run === true) {
      page += 1;
      const tags = await this.tagService.getTags(page, 500, ' ').toPromise()
      .catch(err => this.handleError(err));
      if (tags !== null && tags.length > 0) {
        for (const tag of tags) {
          if (!this.rtagslist.includes(tag.tag_name)) {
            this.tags.push(tag);
          }
        }
      } else {
        run = false;
      }
    }
    this.loading = false;
  }

  async getGroups() {
    this.loading = true;
    let page = 0;
    let run = true;
    while (run === true) {
      page += 1;
      const groups = await this.groupService.getGroups(page, 500).toPromise()
      .catch(err => this.handleError(err));
      if (groups !== null && groups.length > 0) {
        for (const group of groups) {
          this.groups.push(group);
          this.recipegroups.push({ label: group.groupName + '  ', value: group });
          /*if (!this.rpermlist.includes(group.groupName)) {
            this.groups.push(group);
          }*/
        }
      } else {
        run = false;
      }
    }
    this.loading = false;
  }

  async getOrganisation() {
    this.loading = true;
    const org = await this.orgService.getOrganisation().toPromise().catch(err => this.handleError(err));
    this.organisation = org;
    this.loading = false;
  }

  async loadMoreR() {
    this.rpage += 1;
    await this.getRecipes();
  }

  async loadMoreP() {
    this.ppage += 1;
    await this.getParameters();
  }

  async loadMoreS() {
    this.spage += 1;
    await this.getSteps();
  }

  async recipeClicked(recipe) {
    this.activeIndex = 0;
    this.editmode = false;
    this.rtags = [];
    this.rpermissions = [];
    this.tags = [];
    this.groups = [];
    this.cancel();
    this.steps = [];
    this.parameters = [];
    this.recipe = recipe;
    localStorage.setItem('recipe', JSON.stringify(recipe));
    for (const val of this.recipes) {
      if (val.rcp_id === recipe.rcp_id) {
        val.color = 'lightgray';
      } else {
        val.color = 'transparent';
      }
    }

    const rec = await this.recipeService.getRecipes(1, 1, recipe.rcp_id, ' ', 'notfiltering').toPromise()
    .catch(err => this.handleError(err));
    if (rec != null && rec.length > 0) {
      this.setRecipe(rec[0]);
    } else {
      this.recipe = recipe;
    }

    if (this.recipe.status === 'R' || this.recipe.status === 'B') {
      this.isblockedorreleased = true;
    } else {
      this.isblockedorreleased = false;
    }

    await this.getRTags();
    await this.getRPermissions();
    this.isdetail = true;
    this.loading = false;
  }

  async handleChange(e) {
    if (e.index === 1) {
      this.spage = 1;
      this.steps = [];
      await this.getSteps();
    }
    if (e.index === 2) {
      this.ppage = 1;
      this.parameters = [];
      await this.getParameters();
    }
  }

  async addStep() {
    if (this.stepNr === undefined || this.stepNr === null || this.stepNr.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPNUMBER').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepId === undefined || this.stepId === null || this.stepId.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPID').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepDesc === undefined || this.stepDesc === null || this.stepDesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepMand === undefined || this.stepMand === null) {
      this.translate.get('GENERAL.SELECTMANDATORYOPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepUnit === undefined || this.stepUnit === null) {
      this.translate.get('GENERAL.SELECTUNIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepLow === undefined || this.stepLow === null || this.stepLow.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERLOWLIMIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepHigh === undefined || this.stepHigh === null || this.stepHigh.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERHIGHLIMIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const step = new Step();

      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

      step.org_id = +oi;
      step.rcp_id = this.recipe.rcp_id;
      step.step_nr = this.stepNr;
      step.step_id = this.stepId;
      step.step_desc = this.stepDesc;
      step.step_type = 'N';
      step.unit_id = this.stepUnit.val_unit_id;
      step.ll = this.stepLow;
      step.ul = this.stepHigh;
      step.step_mandatory = this.stepMand;

      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

      step.Groups = mg;

      this.loading = true;
      const res = await this.stepService.postStep(step).toPromise().catch(err => this.handleError(err));
      if (res != null && res === true) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.STEPCREATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.spage = 1;
        this.steps = [];
        await this.getSteps();
        this.isaddstep = false;
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.STEPNOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async editStep() {
    if (this.stepNr === undefined || this.stepNr === null || this.stepNr.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPNUMBER').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepId === undefined || this.stepId === null || this.stepId.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPID').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepDesc === undefined || this.stepDesc === null || this.stepDesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSTEPDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepMand === undefined || this.stepMand === null) {
      this.translate.get('GENERAL.SELECTMANDATORYOPTION').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepUnit === undefined || this.stepUnit === null) {
      this.translate.get('GENERAL.SELECTUNIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepLow === undefined || this.stepLow === null || this.stepLow.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERLOWLIMIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.stepHigh === undefined || this.stepHigh === null || this.stepHigh.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERHIGHLIMIT').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const val = new StringUpdater();
      val.Id = this.step.step_id;
      val.Values = [];

      const v = new Variables();
      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

      val.AdditionalValues = mg;

      const desc = new KeyValue();
      desc.Key = 'desc';
      desc.Value = this.stepDesc;
      val.Values.push(desc);

      const ll = new KeyValue();
      ll.Key = 'll';
      ll.Value = this.stepLow.toString();
      val.Values.push(ll);

      const ul = new KeyValue();
      ul.Key = 'ul';
      ul.Value = this.stepHigh.toString();
      val.Values.push(ul);

      const unit = new KeyValue();
      unit.Key = 'val_unit_id';
      unit.Value = this.stepUnit.val_unit_id;
      val.Values.push(unit);

      const mandatory = new KeyValue();
      mandatory.Key = 'mandatory';
      mandatory.Value = this.stepMand;
      val.Values.push(mandatory);

      this.loading = true;
      const res = await this.stepService.putStep(this.recipe.rcp_id, val).toPromise().catch(err => this.handleError(err));
      if (res != null && res === true) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.STEPUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.spage = 1;
        this.steps = [];
        await this.getSteps();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.STEPNOTUPDATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async deleteStep(step) {
    this.loading = true;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    step.Groups = mg;
    const res = await this.stepService.deleteStep(step).toPromise().catch(err => this.handleError(err));
    if (res !== undefined && res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.STEPDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.spage = 1;
      this.steps = [];
      await this.getSteps();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.STEPNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async addParam() {
    if (this.paramName === undefined || this.paramName === null || this.paramName.trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.paramDesc === undefined || this.paramDesc === null || this.paramDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.paramValue === undefined || this.paramValue === null || this.paramValue.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERVALUE').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const param = new RecipeParameter();
      param.rcp_id = this.recipe.rcp_id;
      param.par_id = this.paramName;
      param.par_desc = this.paramDesc;
      param.par_val = this.paramValue;

      const v = new Variables();
      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

      param.Groups = mg;

      this.loading = true;
      const res = await this.paramService.postRecipeParameter(param).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res.toString().trim() !== '') {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.PARAMETERCREATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.ppage = 1;
        this.parameters = [];
        await this.getParameters();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.PARAMETERNOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async editParam() {
    if (this.paramName === undefined || this.paramName === null || this.paramName.trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.paramDesc === undefined || this.paramDesc === null || this.paramDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.paramValue === undefined || this.paramValue === null || this.paramValue.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERPARAMETERVALUE').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const val = new StringUpdater();
      val.Id = this.parameter.par_id;
      val.Values = [];

      const v = new Variables();
      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

      val.AdditionalValues = mg;

      const desc = new KeyValue();
      desc.Key = 'desc';
      desc.Value = this.paramDesc;
      val.Values.push(desc);

      const par = new KeyValue();
      par.Key = 'par_val';
      par.Value = this.paramValue.toString();
      val.Values.push(par);

      this.loading = true;
      const res = await this.paramService.putRecipeParameter(this.recipe.rcp_id, val).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.PARAMETERUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.ppage = 1;
        this.parameters = [];
        await this.getParameters();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.PARAMETERNOTUPDATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async deleteParam(param) {
    this.loading = true;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    param.Groups = mg;
    const res = await this.paramService.deleteRecipeParameter(param).toPromise().catch(err => this.handleError(err));
    if (res !== undefined && res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PARAMETERDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.ppage = 1;
      this.parameters = [];
      await this.getParameters();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.PARAMETERNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async showAddStep() {
    this.units = [];
    await this.getUnits(null);
    this.isaddstep = true;
  }

  async showEditStep(step) {
    this.step = step;
    this.stepNr = step.step_nr;
    this.stepId = step.step_id;
    this.stepDesc = step.step_desc;
    this.stepMand = step.step_mandatory;
    this.stepLow = step.ll;
    this.stepHigh = step.ul;

    this.units = [];
    await this.getUnits(step);
    this.iseditstep = true;
  }

  showDeleteStep(step) {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + step.step_id + '?', 'deletestep', step);
    });
  }

  async showAddParam() {
    this.isaddparameter = true;
  }

  showEditParam(param) {
    this.parameter = param;
    this.paramName = param.par_id;
    this.paramDesc = param.par_desc;
    this.paramValue = param.par_val;

    this.iseditparameter = true;
  }

  showDeleteParam(param) {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + param.par_id + '?', 'deleteparam', param);
    });
  }

  async showAddPermission() {
    this.groups = [];
    await this.getGroups();
    this.ispermadd = true;
  }

  showDeleteRecipePerm(perm) {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + perm.userGroup + ' (' + perm.permission + ')' + '?', 'deleteperm', perm);
    });
  }

  async showAddTag() {
    this.tags = [];
    await this.getTags();
    this.istagadd = true;
  }

  showDeleteRecipeTag(tag) {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + tag.tag_name + '?', 'deletetag', tag);
    });
  }

  showAddSessionId() {
    this.sessionid2 = this.recipe.name_sess_ext_id2;
    this.sessionid3 = this.recipe.name_sess_ext_id3;
    this.sessionid4 = this.recipe.name_sess_ext_id4;
    this.sessionid5 = this.recipe.name_sess_ext_id5;
    this.issessionadd = true;
  }

  showDeleteRecipeSSID(sess, key) {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
      this.confirm(resp + ' ' + sess + '?', 'deletesess', key);
    });
  }

  async showAddRecipe() {
    this.recipegroups = [];
    await this.getOrganisation();
    await this.getGroups();
    this.isaddrecipe = true;
  }

  async showEditRecipe() {
    await this.getOrganisation();
    this.statuses = [];
    if (this.recipe.status === 'E') {
      this.translate.get('GENERAL.EDIT').subscribe((e: string) => {
        this.translate.get('GENERAL.RELEASED').subscribe((r: string) => {
          this.translate.get('GENERAL.BLOCKED').subscribe((b: string) => {
            this.statuses.push({ label: e + ' ', value: 'E', id: 'statedit'});
            this.statuses.push({ label: r + ' ', value: 'R', id: 'statreleased'});
            // this.statuses.push({ label: b + ' ', value: 'B', id: 'statblocked'});
          });
        });
      });
    } else if (this.recipe.status === 'B') {
      this.translate.get('GENERAL.RELEASED').subscribe((r: string) => {
        this.translate.get('GENERAL.BLOCKED').subscribe((b: string) => {
          this.statuses.push({ label: r + ' ', value: 'R', id: 'statreleased'});
          this.statuses.push({ label: b + ' ', value: 'B', id: 'statblocked'});
        });
      });
    } else if (this.recipe.status === 'R') {
      this.translate.get('GENERAL.RELEASED').subscribe((r: string) => {
        this.translate.get('GENERAL.BLOCKED').subscribe((b: string) => {
          this.statuses.push({ label: r + ' ', value: 'R', id: 'statreleased'});
          this.statuses.push({ label: b + ' ', value: 'B', id: 'statblocked'});
        });
      });
    }

    this.recipeName = this.recipe.rcp_name;
    this.recipeDesc = this.recipe.rcp_desc;
    this.recipeStatus = this.recipe.status;
    this.recipeSession = this.recipe.name_sess_ext_id1;

    this.iseditrecipe = true;
  }

  async addPermission(group, p) {
    this.loading = true;
    const perm = new RecipePermission();
    perm.RecipeId = this.recipe.rcp_id;
    perm.UserGroup = group.groupName;
    perm.Permission = p;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const un = localStorage.getItem('un');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const name = CryptoJS.AES.decrypt(un, v.pass).toString(CryptoJS.enc.Utf8);

    perm.Groups = mg;
    perm.Operator = name;

    const res = await this.permService.postRecipePermission(perm).toPromise()
    .catch(err => this.handleError(err));
    if (res != null && res > 0) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONADDED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      this.rpermissions = [];
      await this.getRPermissions();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONNOTADDED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async deletePermission(perm) {
    this.loading = true;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    perm.Groups = mg;
    const res = await this.permService.deleteRecipePermission(perm).toPromise()
    .catch(err => this.handleError(err));
    if (res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      this.rpermissions = [];
      await this.getRPermissions();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async addTag(tag) {
    this.loading = true;
    const rtag = new RecipeTag();
    rtag.rcp_id = this.recipe.rcp_id;
    rtag.tag_id = tag.tag_id;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    rtag.Groups = mg;
    const res = await this.rtagService.postRecipeTag(rtag).toPromise().catch(err => this.handleError(err));
    if (res != null && res > 0) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGADDED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      this.rtags = [];
      await this.getRTags();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGNOTADDED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async deleteTag(tag) {
    this.loading = true;
    const rtag = new RecipeTag();
    rtag.rcp_id = tag.rcp_id;
    rtag.tag_id = tag.tag_id;

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    rtag.Groups = mg;
    const res = await this.rtagService.deleteRecipeTag(rtag).toPromise().catch(err => this.handleError(err));
    if (res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      this.rtags = [];
      await this.getRTags();
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.TAGNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async saveSessions() {
    this.loading = true;

    const val = new Int64Updater();
    val.Id = this.recipe.rcp_id;
    val.Values = [];

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    val.AdditionalValues = mg;

    if (this.sessionid2 !== undefined && this.sessionid2 !== null && this.sessionid2.trim() !== '') {
      const ext2 = new KeyValue();
      ext2.Key = 'ext2';
      ext2.Value = this.sessionid2;
      val.Values.push(ext2);
    }
    if (this.sessionid3 !== undefined && this.sessionid3 !== null && this.sessionid3.trim() !== '') {
      const ext3 = new KeyValue();
      ext3.Key = 'ext3';
      ext3.Value = this.sessionid3;
      val.Values.push(ext3);
    }
    if (this.sessionid4 !== undefined && this.sessionid4 !== null && this.sessionid4.trim() !== '') {
      const ext4 = new KeyValue();
      ext4.Key = 'ext4';
      ext4.Value = this.sessionid4;
      val.Values.push(ext4);
    }
    if (this.sessionid5 !== undefined && this.sessionid5 !== null && this.sessionid5.trim() !== '') {
      const ext5 = new KeyValue();
      ext5.Key = 'ext5';
      ext5.Value = this.sessionid5;
      val.Values.push(ext5);
    }

    const res = await this.recipeService.putRecipe(val).toPromise().catch(err => this.handleError(err));
    if (res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.SESSIONIDSSAVED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      const rec = await this.recipeService.getRecipes(1, 1, this.recipe.rcp_id, ' ', 'notfiltering').toPromise()
      .catch(err => this.handleError(err));
      if (rec != null && rec.length > 0) {
        this.setRecipe(rec[0]);
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.COULDNOTGETRECIPE').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.SESSIONIDSNOTSAVED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  async deleteSessionId(key) {
    this.loading = true;

    const val = new Int64Updater();
    val.Id = this.recipe.rcp_id;
    val.Values = [];

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    val.AdditionalValues = mg;

    const ext = new KeyValue();
    ext.Key = key;
    ext.Value = '';
    val.Values.push(ext);

    const res = await this.recipeService.putRecipe(val).toPromise().catch(err => this.handleError(err));
    if (res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.SESSIONIDDELETED').subscribe((resp2: string) => {
          this.showSuccess(resp, resp2);
        });
      });

      this.cancel();
      const rec = await this.recipeService.getRecipes(1, 1, this.recipe.rcp_id, ' ', 'notfiltering').toPromise()
      .catch(err => this.handleError(err));
      if (rec != null && rec.length > 0) {
        this.setRecipe(rec[0]);
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.COULDNOTGETRECIPE').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.SESSIONIDNOTDELETED').subscribe((resp2: string) => {
          this.showError(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  setRecipe(r) {
    if (r.status === 'E') {
      this.translate.get('GENERAL.EDIT').subscribe((res: string) => {
        r.stat = res;
      });
      r.statcolor = 'yellow';
    }
    if (r.status === 'R') {
      this.translate.get('GENERAL.RELEASED').subscribe((res: string) => {
        r.stat = res;
      });
      r.statcolor = 'green';
    }
    if (r.status === 'B') {
      this.translate.get('GENERAL.BLOCKED').subscribe((res: string) => {
        r.stat = res;
      });
      r.statcolor = 'red';
    }

    for (const rec of this.recipes) {
      if (rec.rcp_id === r.rcp_id) {
        rec.stat = r.stat;
        rec.statcolor = r.statcolor;
        rec.rcp_name = r.rcp_name;
        rec.rcp_desc = r.rcp_desc;
        rec.name_sess_ext_id1 = r.name_sess_ext_id1;
        rec.name_sess_ext_id2 = r.name_sess_ext_id2;
        rec.name_sess_ext_id3 = r.name_sess_ext_id3;
        rec.name_sess_ext_id4 = r.name_sess_ext_id4;
        rec.name_sess_ext_id5 = r.name_sess_ext_id5;
        rec.released = r.released;
        rec.status = r.status;
        rec.oprator = r.oprator;
        rec.created = r.created;
      }
    }

    this.recipe = r;

    if (this.recipe.status === 'R' || this.recipe.status === 'B') {
      this.isblockedorreleased = true;
    } else {
      this.isblockedorreleased = false;
    }
  }

  async addRecipe() {
    if (this.recipeName === undefined || this.recipeName === null || this.recipeName.trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPENAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.recipeDesc === undefined || this.recipeDesc === null || this.recipeDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPEDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.recipeSession === undefined || this.recipeSession === null || this.recipeSession.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPESESSIONID1').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.recipeGroup === undefined || this.recipeGroup === null) {
      this.translate.get('GENERAL.SELECTRECIPEPERMISSIONGROUP').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const rec = new Recipe();
      rec.org_id = this.organisation.organisationId;
      rec.rcp_name = this.recipeName;
      rec.rcp_desc = this.recipeDesc;
      rec.name_sess_ext_id1 = this.recipeSession;

      const v = new Variables();
      const un = localStorage.getItem('un');
      const name = CryptoJS.AES.decrypt(un, v.pass).toString(CryptoJS.enc.Utf8);

      rec.oprator = name;

      const perm = new RecipePermission();
      perm.UserGroup = this.recipeGroup.groupName;
      perm.Permission = 'W';
      perm.Operator = name;

      rec.Permission = perm;

      this.loading = true;
      const res = await this.recipeService.postRecipe(rec).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.RECIPECREATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        this.recipe = null;
        this.rpage = 1;
        this.recipes = [];
        await this.getRecipes();
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.RECIPENOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  async editRecipe() {
    if (this.recipeName === undefined || this.recipeName === null || this.recipeName.trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPENAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.recipeDesc === undefined || this.recipeDesc === null || this.recipeDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPEDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (this.recipeSession === undefined || this.recipeSession === null || this.recipeSession.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERRECIPESESSIONID1').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      const val = new Int64Updater();
      val.Id = this.recipe.rcp_id;
      val.Values = [];

      const v = new Variables();
      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

      val.AdditionalValues = mg;

      if (this.recipeStatus === 'R' && this.recipe.status !== 'R') {
        const release = new KeyValue();
        release.Key = 'release';
        release.Value = 'R';
        val.Values.push(release);
      } else if (this.recipeStatus === 'B' && this.recipe.status !== 'B') {
        const blocked = new KeyValue();
        blocked.Key = 'blocked';
        blocked.Value = 'B';
        val.Values.push(blocked);
      } else if (this.recipeStatus === 'E') {
        const desc = new KeyValue();
        desc.Key = 'desc';
        desc.Value = this.recipeDesc;
        val.Values.push(desc);

        const ext1 = new KeyValue();
        ext1.Key = 'ext1';
        ext1.Value = this.recipeSession;
        val.Values.push(ext1);
      }

      if (val.Values.length === 0) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.RECIPEUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        return;
      }

      this.loading = true;
      const res = await this.recipeService.putRecipe(val).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.RECIPEUPDATED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });

        this.cancel();
        const rec = await this.recipeService.getRecipes(1, 1, this.recipe.rcp_id, ' ', 'notfiltering').toPromise()
        .catch(err => this.handleError(err));
        if (rec != null && rec.length > 0) {
          this.setRecipe(rec[0]);
        } else {
          this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
            this.translate.get('GENERAL.COULDNOTGETRECIPE').subscribe((resp2: string) => {
              this.showError(resp, resp2);
            });
          });
        }
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.RECIPENOTIPDATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }
      this.loading = false;
    }
  }

  startEditMode() {
    this.editmode = true;
    this.divname = 'disableddiv';
  }

  exitEditMode() {
    this.editmode = false;
    this.divname = 'notdisableddiv';
  }

  cancel() {
    this.isaddstep = false;
    this.iseditstep = false;
    this.isaddparameter = false;
    this.iseditparameter = false;
    this.ispermadd = false;
    this.istagadd = false;
    this.issessionadd = false;
    this.isaddrecipe = false;
    this.iseditrecipe = false;

    this.stepNr = null;
    this.stepId = null;
    this.stepDesc = null;
    this.stepUnit = null;
    this.stepMand = null;
    this.stepLow = null;
    this.stepHigh = null;

    this.paramName = null;
    this.paramDesc = null;
    this.paramValue = null;

    this.sessionid2 = null;
    this.sessionid3 = null;
    this.sessionid4 = null;
    this.sessionid5 = null;

    this.recipeName = null;
    this.recipeDesc = null;
    this.recipeStatus = null;
    this.recipeSession = null;
    this.recipeGroup = null;

    this.isdelete = false;
  }

  confirm(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
          // Accept logic
          if (operation != null && operation === 'deletestep') {
            // delete
            await this.deleteStep(value);
          }
          if (operation != null && operation === 'deleteparam') {
            // delete
            await this.deleteParam(value);
          }
          if (operation != null && operation === 'deleteperm') {
            // delete
            await this.deletePermission(value);
          }
          if (operation != null && operation === 'deletetag') {
            // delete
            await this.deleteTag(value);
          }
          if (operation != null && operation === 'deletesess') {
            // delete
            await this.deleteSessionId(value);
          }
          this.isdelete = false;
        },
        reject: () => {
          // Reject logic
          this.isdelete = false;
        }
    });
  }

  confirm2(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
          // Accept logic
        },
        reject: () => {
          // Reject logic
        },
        key: '2'
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    // this.cancel();
    if (err != null && err.error != null) {
      if (err.error.MessageText !== undefined && err.error.MessageText != null
         && err.error.MessageText === 'duplicate key value violates unique constraint "step_id_constraint"'
         || err.error.MessageText === 'duplicate key value violates unique constraint "step_nr_constraint"') {
        this.translate.get('GENERAL.STEPALREADYEXISTS').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.MessageText !== undefined && err.error.MessageText != null
         && err.error.MessageText === 'duplicate key value violates unique constraint "par_id_constraint"') {
        this.translate.get('GENERAL.PARAMETERALREADYEXISTS').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.MessageText !== undefined && err.error.MessageText != null
         && err.error.MessageText === 'duplicate key value violates unique constraint "cstr_rcp_prm"') {
        this.translate.get('GENERAL.PERMISSIONALREADYEXISTS').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.MessageText !== undefined && err.error.MessageText != null
         && err.error.MessageText === 'duplicate key value violates unique constraint "cstr_rcp_name"') {
        this.translate.get('GENERAL.RECIPEALREADYEXISTS').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.MessageText !== undefined && err.error.MessageText != null && err.error.MessageText.includes('value too long')) {
        this.translate.get('GENERAL.VALUETOOLONG').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.Message !== undefined && err.error.Message != null
         && err.error.Message === 'User does not belong to any of the edit permission groups of the recipe') {
        this.translate.get('GENERAL.USERDOESNOTHAVEEDITPERMISSION').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.Message !== undefined && err.error.Message != null
        && err.error.Message ===
        'Cannot delete all edit permissions. At least one edit permission must always be assigned to a recipe') {
        this.translate.get('GENERAL.CANNOTDELETEALLEDITPERMISSIONS').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else if (err.error.Message !== undefined && err.error.Message != null
        && err.error.Message === 'Recipe is not in edit mode. ') {
        this.translate.get('GENERAL.RECIPENOTINEDITMODE').subscribe((resp: string) => {
          this.confirm2(resp, null, null);
        });
      } else {
        console.log(err);
      }
    } else {
      console.log(err);
    }
  }

}
