import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { AnalyticsValueTrendService } from './route-guards/analytics-value-trend/analytics-value-trend.service';
import { AnalyticsSDetailService } from './route-guards/analytics-s-detail/analytics-s-detail.service';
import { AnalyticsSOverviewService } from './route-guards/analytics-s-overview/analytics-s-overview.service';
import { AdminUnitService } from './route-guards/admin-unit/admin-unit.service';
import { AdminTagService } from './route-guards/admin-tag/admin-tag.service';
import { SessionDetailInfoComponent } from './components/session-detail-info/session-detail-info.component';
import { SessionOverviewComponent } from './components/session-overview/session-overview.component';
import { SessionDetailComponent } from './components/session-detail/session-detail.component';
import { SessionValueTrendComponent } from './components/session-value-trend/session-value-trend.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipeComponent } from './components/recipe/recipe.component';
import { TagComponent } from './components/tag/tag.component';
import { UnitComponent } from './components/unit/unit.component';
import { RecipeService } from './route-guards/recipe/recipe.service';
import { BrowserComponent } from './components/browser/browser.component';


const routes: Routes = [
  { path: 'unauthorized-user', component: UnauthorizedUserComponent },
  { path: 'recipes', component: RecipeComponent, canActivate: [ RecipeService ] },
  { path: 'tags', component: TagComponent, canActivate: [ AdminTagService ] },
  { path: 'units', component: UnitComponent, canActivate: [ AdminUnitService ] },
  { path: 'session-overview', component: SessionOverviewComponent, canActivate: [ AnalyticsSOverviewService ] },
  { path: 'session-detail', component: SessionDetailComponent, canActivate: [ AnalyticsSDetailService ] },
  { path: 'session-detail-info', component: SessionDetailInfoComponent, canActivate: [ AnalyticsSDetailService ] },
  { path: 'test-step-value-trend', component: SessionValueTrendComponent, canActivate: [ AnalyticsValueTrendService ] },
  { path: 'xxxxx', component: BrowserComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
