export class Tag {
    // tslint:disable-next-line: variable-name
    public org_id: number;
    // tslint:disable-next-line: variable-name
    public tag_id: number;
    // tslint:disable-next-line: variable-name
    public tag_name: string;
    // tslint:disable-next-line: variable-name
    public tag_desc: string;
    public created: Date;
}
