import { RecipeTag } from './../../models/recipe-tag';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { RecipePermission } from 'src/app/models/recipe-permission';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class RecipeTagService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRecipeTags(rid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/recipetag/' + rid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRecipeTag(rtag: RecipeTag) {
    return this.httpclient.post(this.apiUrl + '/api/recipetag', rtag)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipeTag(rtag: RecipeTag): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/recipetag/delete/1', rtag, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipeTag2(rtag: RecipeTag): Observable<any> {
    const val = JSON.stringify(rtag);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/recipetag/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
