import { BrowserComponent } from './components/browser/browser.component';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { SessionValueTrendComponent } from './components/session-value-trend/session-value-trend.component';
import { SessionDetailInfoComponent } from './components/session-detail-info/session-detail-info.component';
import { SessionDetailComponent } from './components/session-detail/session-detail.component';
import { SessionOverviewComponent } from './components/session-overview/session-overview.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { enableProdMode, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

import { AccordionModule } from 'primeng/accordion'; // accordion and accordion tab
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'; // api
import { RecipeComponent } from './components/recipe/recipe.component';
import { TagComponent } from './components/tag/tag.component';
import { UnitComponent } from './components/unit/unit.component';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { ToastModule } from 'primeng/toast';
import { Router } from '@angular/router';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { detect } from 'detect-browser';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    RecipeComponent,
    TagComponent,
    UnitComponent,
    SessionOverviewComponent,
    SessionDetailComponent,
    SessionDetailInfoComponent,
    SessionValueTrendComponent,
    UnauthorizedUserComponent,
    BrowserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    AccordionModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    TabViewModule,
    DialogModule,
    FormsModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    InputNumberModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    ToastModule,
    ChartModule,
    CalendarModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    CookieService,
    MessageService,
    ConfirmationService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],

  //  bootstrap: [
  //     AppComponent
  //  ]
  entryComponents: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    ) {
    // localStorage.clear();
    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
  }

  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app');

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production });
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
  }
 }
